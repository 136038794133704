@use 'src/styles/fonts.scss';

.container {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  position: relative;
}

.logo {
  width: 50px;
  cursor: pointer;
}

.logo:hover {
  filter: brightness(80%);
}

.container .burger {
  font-size: 22px;
  color: var(--text-color);
  position: absolute;
  top: 30px;
  right: 15px;
}

.container .burger:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.sidebar {
  background: var(--primary-color);
  position: fixed;
  display: flex;
  top: 0px;
  right: 0px;
  z-index: 1000;
  height: 100vh;
  width: 220px;
}

.sidebar .burger {
  font-size: 22px;
  color: var(--bg-color);
  position: absolute;
  top: 30px;
  right: 40px;
}

.sidebar .burger:hover {
  color: var(--text-color);
  cursor: pointer;
}

.sidebar .text-routes {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  padding-left: 30px;
}

.sidebar .text-routes h1 {
  margin-top: 10px;
  font-size: 22px;
  cursor: pointer;
}

.sidebar .text-routes h1:hover {
  color: var(--text-color);
}

.sidebar .toggle-container {
  margin-top: 15px;
}

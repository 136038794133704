@use 'src/styles/fonts.scss';

.page-container {
  overflow: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  margin-top: 40px;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-container img {
  width: 400px;
}

.content-container h1 {
  margin-top: 34px;
  font-family: Roboto-Bold;
  font-size: 32px;
  color: var(--text-color);
}

.content-container h2 {
  font-family: Roboto-Regular;
  font-size: 28px;
  color: var(--text-color);
  text-align: left;
  width: 100%;
  height: 20px;
  font-weight: 600;
  margin-bottom: 40px;
}

.content-container p {
  text-align: left;
  color: var(--text-color);
  width: 96%;
  left: 2%;
  font-size: 26px;
}

.highlight-text {
  text-decoration: underline;
  font-weight: 600;
}

.content-description p {
  font-size: 18px;
  width: 96%;
  text-align: left;
  margin: auto auto;
  margin-top: 40px;
  margin-bottom: 20px;
}

.content-credits {
  font-size: 18px;
  width: 96%;
  text-align: center;
  margin: auto auto;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--text-color);
}

@media only screen and (max-width: 800px) {
  .content-container {
    padding: 0px 30px;
    width: 100%;
    margin-top: 10px;
  }

  .content-container img {
    width: calc(100% - 60px);
  }
}

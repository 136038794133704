@use 'src/styles/fonts.scss';

.staff-container {
  width: 100%;
  height: 160px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border-radius: 0px;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 2px solid rgb(40, 195, 247);
  // padding-bottom: 10px;
  // box-shadow: 0px 0px 200px var(--card-shadow-color);
}

.staff-container.small {
  width: 100%;
  height: 100px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border-radius: 0px;
  margin-bottom: 10px;
}

.staff-image {
  position: relative;
  height: 100px;
  width: 100px;
  top: 10px;
  left: 20px;
  float: left;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50px;
  border: 1px solid rgb(40, 195, 247);
}

.staff-image.small {
  position: relative;
  height: 60px;
  width: 60px;
  top: 10px;
  left: 40px;
  border-radius: 30px;
}

.staff-name {
  text-align: left;
  font-size: 28px;
  margin-left: 0px;
  width: calc(100% - 240px);
  height: 45px;
  margin-top: 0px;
}

.staff-text {
  text-align: left;
  margin-left: 140px;
}

.staff-description {
  text-align: left;
  font-size: 18px;
  margin-top: 5px;
  margin-left: 0px;
  width: calc(100% - 30px);
  // height: calc(100% - 45px);
}

.staff-OH {
  text-align: left;
  font-size: 20px;
  margin-top: 10px;
  margin-left: 0px;
  width: calc(100% - 30px);
  // height: calc(100% - 45px);
}

@media only screen and (max-width: 800px) {
  .staff-name {
    font-size: 22px;
    width: calc(100% - 120px);
  }
  
  .staff-description {
    font-size: 16px;
  }
  
  .staff-OH {
    font-size: 18px;
  }
}
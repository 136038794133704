@use 'src/styles/fonts.scss';

.page-container {
  overflow: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  margin-top: 100px;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container {
  width: 50%;
}

.text-container h1 {
  font-family: Roboto-Bold;
  font-size: 80px;
  color: var(--text-color);
  line-height: 110px;
  margin: 0px 0px 20px 0px;
}

.text-container h3 {
  font-family: Roboto-Regular;
  font-size: 18px;
  color: var(--text-color);
}

.graphic-container {
  margin-left: 100px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graphic-container img {
  width: 400px;
}

@media only screen and (max-width: 800px) {
  .content-container {
    padding: 0px 30px;
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
  }

  .text-container {
    width: 100%;
    margin-bottom: 30px;
  }

  .text-container h1 {
    font-size: 60px;
    line-height: 80px;
  }

  .graphic-container {
    width: 100%;
    margin-left: 0px;
  }

  .graphic-container img {
    width: calc(100% - 60px);
  }
}

@use 'src/styles/fonts.scss';

.doc-container {
  width: 100%;
  height: 200px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border-radius: 5px;
  margin-bottom: 50px;
  position: relative;
  // border-bottom: 2px solid black;
  // padding-bottom: 10px;
  box-shadow: 0px 0px var(--card-shadow-radius) var(--card-shadow-color);
}

.doc-image {
  position: absolute;
  height: 200px;
  width: 200px;
  top: 0px;
  right: 0px;
  // float: right;
  overflow: hidden;
  object-fit: contain;
}

.doc-name {
  text-align: left;
  font-size: 22px;
  margin-left: 10px;
  width: calc(100% - 240px);
  height: 40px;
  margin-top: 10px;
  margin-bottom: 15px;
  // background-color: red;
  border-bottom: 1px solid rgb(40, 195, 247);
}

.doc-description {
  text-align: left;
  font-size: 18px;
  margin-top: 5px;
  margin-left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 45px);
}

.doc-description-short {
  text-align: left;
  font-size: 18px;
  margin-top: 5px;
  margin-left: 15px;
  width: calc(100% - 240px);
  height: calc(100% - 45px);
}

.doc-repo a {
}

@media only screen and (max-width: 800px) {
  .doc-name {
    font-size: 22px;
    width: calc(100% - 120px);
    height: 40px;
  }
  
  .doc-description {
    font-size: 14px;
  }

  .doc-description-short {
    font-size: 14px;
  }
  
  // .project-team {
  //   font-size: 10px;
  // }
}
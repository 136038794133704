@use 'src/styles/fonts.scss';

.container {
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

.logo {
  width: 50px;
  cursor: pointer;
}

.logo:hover {
  filter: brightness(80%);
}

.text-routes {
  display: flex;
}

.text-routes h1 {
  font-family: Roboto-Bold;
  font-size: 18px;
  color: var(--text-color);
  margin-left: 60px;
  cursor: pointer;
}

.text-routes h1:hover {
  color: var(--primary-color);
}

.toggle-container {
  margin-left: 60px;
}

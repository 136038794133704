@use 'src/styles/fonts.scss';

:root {
  --image-width: 220px;
}

.project-container {
  width: 100%;
  height: 240px;
  background-color: var(--bg-color);
  color: var(--text-color);
  margin-bottom: 50px;
  position: relative;
  // border-bottom: 2px solid black;
  // border-top: 2px solid black;
  border-radius: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  // padding-top: 10px;
  box-shadow: 0px 0px var(--card-shadow-radius) var(--card-shadow-color);
}

.project-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: var(--image-width);
  height: 240px;
  top: 0%;
  float: left;
  overflow: hidden;
}

.project-name {
  text-align: left;
  font-size: 28px;
  margin-left: calc(var(--image-width) + 20px);
  width: calc(100% - var(--image-width) - 20px);
  height: 50px;
  border-bottom: 2px solid var(--hr-line);
}

.project-description {
  text-align: left;
  font-size: 16px;
  margin-left: calc(var(--image-width) + 20px);
  margin-top: 5px;
  width: calc(100% - var(--image-width) - 30px);
  height: 120px;
}

.project-team {
  right: 10%;
  margin-right: 5px;
  text-align: right;
  height: 25px;
  // width: calc(25% - 0px);
  bottom: 0px;
  right: 5px;
  position: absolute;
  font-size: 12px;
  // border-top: 1px solid var(--hr-line-vl);
}

.project-links {
  position: absolute;
  margin-left: calc(var(--image-width) + 20px);
  height: 30px;
  bottom: 30px;
  width: calc(100% - var(--image-width) - 20px);
  // background-color: black;
  text-align: center;
  border-bottom: 2px solid var(--hr-line);
}

.project-repo {
  position: absolute;
  // margin-left: calc(var(--image-width) + 20px);
  height: 15px;
  text-align: center;
  bottom: 10px;
  width: 90px;
  font-size: 12px;
}

.project-repo-inactive {
  position: absolute;
  // margin-left: calc(var(--image-width) + 20px);
  height: 15px;
  color: var(--text-color-inactive);
  text-align: center;
  bottom: 10px;
  width: 90px;
  font-size: 12px;
}

.project-repo a {
}

@media only screen and (max-width: 800px) {
  .project-name {
    font-size: 22px;
    width: calc(100% - 120px);
    height: 40px;
  }
  
  .project-description {
    font-size: 14px;
  }
  
  .project-team {
    font-size: 10px;
  }
}
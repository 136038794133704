@use 'src/styles/fonts.scss';

.page-container {
  overflow: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  margin-top: 40px;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-container img {
  width: 400px;
  height: 400px;
}

.content-container h1 {
  margin-top: 34px;
  font-family: Roboto-Bold;
  font-size: 32px;
  color: var(--text-color);
}

.content-container h2 {
  font-family: Roboto-Regular;
  font-size: 28px;
  color: var(--text-color);
  text-align: left;
  width: 100%;
  height: 20px;
  font-weight: 600;
  margin-bottom: 40px;
}

.content-container p {
  text-align: left;
  color: var(--text-color);
  width: 96%;
  left: 2%;
  font-size: 26px;
}

.highlight-text {
  text-decoration: underline;
  font-weight: 600;
}

.faces-container {
  // position: absolute;
  background-color: red;
  // height: 600px;
  top: 100px;
}

.face {
  // background-image: url("https://cpsc.yale.edu/sites/default/files/styles/people_thumbnail/public/pictures/picture-4252-1632336188.jpg?itok=xl_y10tU");
  background-repeat: no-repeat;
  background-size: cover;
  width: 180px;
  height: 180px;
  border-radius: 90px;
  border: 1px solid rgb(40, 195, 247);
}

@media only screen and (max-width: 800px) {
  .content-container {
    padding: 0px 30px;
    width: 100%;
    margin-top: 10px;
  }

  .content-container img {
    width: calc(100% - 60px);
  }
}

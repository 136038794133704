@use 'src/styles/fonts.scss';

.page-container {
  overflow: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  margin-top: 40px;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--text-color);
}

.content-container h1 {
  margin-top: 34px;
  font-family: Roboto-Bold;
  font-size: 32px;
  color: var(--text-color);
}

.content-container h1 {
  margin-top: 34px;
  font-family: Roboto-Bold;
  font-size: 32px;
  color: var(--text-color);
}

.content-container p {
  font-size: 26px;
}

.content-container a {
  font-size: 26px;
}

.hrline {
  background-color: rgb(40, 195, 247);
  color: rgb(40, 195, 247);
  height: 1px;
  width: 100%;
  margin-bottom: 40px;
}

.vrgap {
  height: 40px;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .content-container {
    padding: 0px 30px;
    width: 100%;
    margin-top: 10px;
  }

  .content-container img {
    width: calc(100% - 60px);
  }
}

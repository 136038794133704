@use 'src/styles/fonts.scss';

.page-container {
  overflow: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  margin-top: 40px;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-container img {
  width: 400px;
}

.content-container h1 {
  margin-top: 34px;
  font-family: Roboto-Bold;
  font-size: 32px;
  color: var(--text-color);
}

.content-container h2 {
  font-family: Roboto-Regular;
  font-size: 28px;
  color: var(--text-color);
  text-align: left;
  width: 100%;
  height: 20px;
  font-weight: 600;
  margin-bottom: 40px;
}

.content-container p {
  text-align: left;
  color: var(--text-color);
  width: 96%;
  left: 2%;
  font-size: 26px;
}

.content-container button {
  top: 100px;
  // position: absolute;
  color: rgb(10, 10, 10);
  font-size: 22px;
  width: 200px;
  left: calc((100% - 200px) / 2);
  text-align: center;
  background-color: rgba(63, 207, 255, 0.235);
  border: 2px solid rgba(63, 207, 255, 1);
  display: block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-weight: 400;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 0;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
}

.content-container input {
  top: 350px;
  color: black;
  font-size: 34px;
  font-weight: 200;
  width: 80%;
  left: 10%;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid black;
  box-shadow: 0px 0px 0px 0px black;
}

.highlight-text {
  text-decoration: underline;
  font-weight: 600;
}

.content-description p {
  font-size: 18px;
  width: 96%;
  text-align: left;
  margin: auto auto;
  margin-top: 40px;
  margin-bottom: 20px;
}

.content-credits {
  font-size: 18px;
  width: 96%;
  text-align: center;
  margin: auto auto;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--text-color);
}

@media only screen and (max-width: 800px) {
  .content-container {
    padding: 0px 30px;
    width: 100%;
    margin-top: 10px;
  }

  .content-container img {
    width: calc(100% - 60px);
  }
}

@import '~antd/dist/antd.css';
.ant-switch-checked {
  background-color: #4c4e52;
}
.ant-switch-checked:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

html,
body {
  background: var(--bg-color-real);
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
}

:root[data-theme='theme-light'] {
  --primary-color: #66ccff;
  --text-color: #2c2c2c;
  --text-color-inactive: rgba(21, 21, 21, 0.25);
  --bg-color: #ebebeb;
  --bg-color-real: #f4f4f4;
  --hr-line: rgb(40, 195, 247);
  --hr-line-vl: rgb(249, 110, 231);
  --card-bg-color: rgb(245, 245, 245);
  --card-shadow-color: rgba(100, 100, 100, 0.15);
  --card-shadow-radius: 2px;
}

:root[data-theme='theme-dark'] {
  --primary-color: #49afd9;
  --text-color: #ffffff;
  --text-color-inactive: rgba(250, 250, 250, 0.15);
  --bg-color: #181818;
  --bg-color-real: #080808;
  --hr-line: rgb(40, 195, 247);
  --hr-line-vl: rgb(249, 110, 231);
  --card-bg-color: rgb(58, 58, 58);
  --card-shadow-color: rgba(190, 190, 190, 0.15);
  --card-shadow-radius: 2px;
}
